@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: TopazPlus-a1200;
  src: url("./topaz.eot");
}

@font-face {
  font-family: TopazPlus-a1200;
  src: url("./topaz.ttf") format("truetype");
}

.AnsiText {
  font-family: monospace;
  white-space: pre;
  line-height: 1;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  background: #2B1900;
  min-height: 100vh;
  font-family: TopazPlus-a1200;
  position: absolute;
  width: auto;
  min-width: 100%;
}

.body {
  animation: textShadow 1.033s infinite;
}

.flicker {
  pointer-events: none;
  position: fixed;
  width: auto;
  min-width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(9, 8, 8, 0.1);
  animation: flicker 0.3301s infinite;
}

.scanline {
  position: fixed;
  pointer-events: none;
  top: 0%;
  width: auto;
  min-width: 100%;
  height: 2px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.75;
  animation: scanline 7.77s linear infinite;
}

.scanlines {
  position: fixed;
  pointer-events: none;
  width: auto;
  min-width: 100%;
  height: 100%;
  z-index: 5;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  background-size: 100% 2px, 3px 100%;
}

.caret {
  display: inline-block;
  width: 8px;
  height: 18px;
  animation: blink 1s infinite;
  vertical-align: text-bottom;
}

@keyframes blink {
  0%,
  50% {
    opacity: 1;
  }
  51%,
  100% {
    opacity: 0;
  }
}

@keyframes flicker {
  0% {
    opacity: 0.119;
  }

  5% {
    opacity: 0.02841;
  }

  10% {
    opacity: 0.35748;
  }

  15% {
    opacity: 0.88852;
  }

  20% {
    opacity: 0.9408;
  }

  25% {
    opacity: 0.35088;
  }

  30% {
    opacity: 0.22426;
  }

  35% {
    opacity: 0.26418;
  }

  40% {
    opacity: 0.09249;
  }

  45% {
    opacity: 0.35312;
  }

  50% {
    opacity: 0.89436;
  }

  55% {
    opacity: 0.9574;
  }

  60% {
    opacity: 0.19754;
  }

  65% {
    opacity: 0.05086;
  }

  70% {
    opacity: 0.12137;
  }

  75% {
    opacity: 0.75791;
  }

  80% {
    opacity: 0.89617;
  }

  85% {
    opacity: 0.90183;
  }

  90% {
    opacity: 0.20657;
  }

  95% {
    opacity: 0.64125;
  }

  100% {
    opacity: 0.78042;
  }
}

@keyframes scanline {
  0% {
    top: 99%;
  }
}

@keyframes scanlines {
  0% {
    background-position: 0 50%;
  }
}

@keyframes textShadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0, 30, 255, 0.5),
      -0.4389924193300864px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0, 30, 255, 0.5),
      -2.7928974010788217px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0, 30, 255, 0.5),
      -0.02956275843481219px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0, 30, 255, 0.5),
      -0.40218538552878136px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0, 30, 255, 0.5),
      -3.4794037899852017px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0, 30, 255, 0.5),
      -1.6125630401149584px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0, 30, 255, 0.5),
      -0.7015590085143956px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0, 30, 255, 0.5),
      -3.896914047650351px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0, 30, 255, 0.5),
      -3.870905614848819px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0, 30, 255, 0.5),
      -2.231056963361899px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0, 30, 255, 0.5),
      -0.08084290417898504px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0, 30, 255, 0.5),
      -2.3758461067427543px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0, 30, 255, 0.5),
      -2.202193051050636px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0, 30, 255, 0.5),
      -2.8638780614874975px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0, 30, 255, 0.5),
      -0.48874025155497314px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0, 30, 255, 0.5),
      -1.8948491305757957px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0, 30, 255, 0.5),
      -0.0833037308038857px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0, 30, 255, 0.5),
      -0.09769827255241735px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0, 30, 255, 0.5),
      -3.443339761481782px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0, 30, 255, 0.5),
      -2.1841838852799786px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0, 30, 255, 0.5),
      -2.6208764473832513px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
}
@keyframes glitch-skew {
  0% {
    transform: skew(0deg, 0deg);
  }
  48% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  50% {
    transform: skew(-20deg, 0deg);
    filter: blur(4px);
  }
  52% {
    transform: skew(20deg, 0deg);
  }
  54% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

.display-linebreak {
  white-space: pre-line;
}